import { useEffect, useMemo, useState, useCallback } from 'react'
import FilterButton from '../../components/globals/filter/FilterButton'
import Page from '../../containers/Page'
import TotalCard from '../../components/dashboard/TotalCard'
import ShipmentsBarChart from '../../components/dashboard/ShipmentsBarChart'
import ShipmentTypePieChart from '../../components/dashboard/ShipmentTypePieChart'
import OrderStatus from '../../components/dashboard/OrderStatusCard'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboard } from '../../slices/dashboardSlice'
import Loader from '../../components/loader/Loader'
import { fetchDashboardOrders } from '../../slices/ordersSlice'
import DashboardFilter from '../../components/dashboard/DashboardFilter'
import { fetchBranches } from '../../slices/orgsSlice'
import CeoBookingCard from '../../components/dashboard/CeoBookingCard'
import dayjs from 'dayjs'
import FinancialAnalysis from '../../components/dashboard/FinancialAnalysis'
import { getDate } from '../../utils'
import FleetCard from '../../components/dashboard/FleetCard'

const convertToKeyValueArray = obj => {
  if (obj) {
    return obj
      ? Object?.entries(obj).map(([key, value]) => ({
          title: key,
          value: value
        }))
      : {}
  }
}
export default function CeoDashboard ({ metaTitle }) {
  const dashboardData = useSelector(state => state.dashboard)
  const dashboardOrdersData = useSelector(state => state.orders)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [expandedCardIndex, setExpandedCardIndex] = useState(null)
  const addOnsData = useSelector(state => state.addons.data)
  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const initializeDateRange = () => {
    const now = new Date()
    const prevMonth = new Date()
    prevMonth.setMonth(now.getMonth() - 1)
    const endDate = dayjs(
      `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    )
    const startDate = dayjs(
      `${prevMonth.getFullYear()}-${
        prevMonth.getMonth() + 1
      }-${prevMonth.getDate()}`
    )
    return [startDate, endDate]
  }

  const [dateRange, setDateRange] = useState(initializeDateRange())

  const handleToggle = index => {
    setExpandedCardIndex(index === expandedCardIndex ? null : index)
  }

  const analysisData = useMemo(() => {
    const findAndMergeItemsByIds = updates => {
      return updates
        ?.map(update => {
          const item = addOnsData?.find(item => item.id === update.id)
          if (item) {
            return { ...item, price: update.price, quantity: update.quantity }
          }
          return null
        })
        .filter(mergedItem => mergedItem !== null)
    }
    if (dashboardOrdersData.data) {
      return dashboardOrdersData?.data?.map(item => {
        return {
          date: getDate(item.order_date),
          waybillNo: item.tracking_id,
          fullname: item.receiver.contact.name,
          branch: item.branch_name,
          tpl: item.tpl_service,
          type: item.type,
          weight: item.weight,
          addOns: item?.addon_data?.addons?.length
            ? findAndMergeItemsByIds(item?.addon_data?.addons)
                ?.map(item => `${item.name} (${item.quantity})`)
                .join(',')
            : '--',
          addOnsTotal: item.addon_data?.total_addons_price?.toLocaleString(
            'en-NG',
            {
              style: 'currency',
              currency: 'NGN'
            }
          ),
          shippingFee: item.extra_charges?.on_shipping_fee?.toLocaleString(
            'en-NG',
            {
              style: 'currency',
              currency: 'NGN'
            }
          ),
          payment: item.payment_method ? item.payment_method : '--',
          insuranceFee: item.insurance?.fee?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          }),
          fuel: item.extra_charges?.fuel_surcharge.amount?.toLocaleString(
            'en-NG',
            {
              style: 'currency',
              currency: 'NGN'
            }
          ),
          subTotal: item.subtotal?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          }),
          tax: item.tax?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          }),
          total: item.total?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          })
        }
      })
    }
  }, [dashboardOrdersData.data, addOnsData])

  const CeoCardDetails = useMemo(() => {
    if (dashboardData?.data) {
      const cardData = dashboardData?.data
      return [
        {
          title: 'Walk-In Customers',
          amount: cardData
            ? cardData?.customer_bookings_data?.total_customer_bookings?.toLocaleString(
                'en-NG',
                {
                  style: 'currency',
                  currency: 'NGN'
                }
              )
            : {},
          details: cardData
            ? convertToKeyValueArray(
                cardData?.customer_bookings_data?.branch_booking
              )
            : null
        },
        {
          title: '3PL',
          amount:
            cardData?.order_stat && cardData?.order_stat?.tpl_booking
              ? Object.values(cardData?.order_stat?.tpl_booking)
                  ?.reduce((sum, value) => sum + value, 0)
                  ?.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })
              : null,
          details: cardData
            ? convertToKeyValueArray(cardData?.order_stat?.tpl_booking)?.map(
                item => {
                  return {
                    ...item,
                    value: item?.value?.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN'
                    })
                  }
                }
              )
            : null
        },
        {
          title: 'Cash Payments',
          amount:
            cardData?.order_stat && cardData?.order_stat?.tpl_booking
              ? Object.values(cardData?.order_stat?.tpl_booking)
                  .reduce((sum, value) => sum + value, 0)
                  ?.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })
              : null,
          details: cardData
            ? convertToKeyValueArray(
                cardData?.order_stat?.cash_transactions
              )?.map(item => {
                return {
                  ...item,
                  value: item?.value?.toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN'
                  })
                }
              })
            : {}
        },
        {
          title: 'Shipments Status',
          details: [
            {
              title: 'Pending',
              value: cardData?.shipments ? cardData?.shipments?.pending : '--'
            },
            {
              title: 'In transit',
              value: cardData?.shipments
                ? cardData?.shipments['in-transit']
                : '--'
            },
            {
              title: 'Voided',
              value: cardData?.shipments ? cardData?.shipments?.voided : '--'
            },
            {
              title: 'Exception',
              value: cardData?.shipments ? cardData?.shipments?.exception : '--'
            },
            {
              title: 'Out for delivery',
              value: cardData?.shipments
                ? cardData?.shipments['available-for-pickup-by-customer']
                : '--'
            },
            {
              title: 'Ready for pickup',
              value: cardData?.shipments
                ? cardData?.shipments['available-for-pickup-by-customer']
                : '--'
            },
            {
              title: 'Delivered',
              value: cardData?.shipments
                ? cardData?.shipments?.delivered
                : ' --'
            }
          ]
        }
        // {
        //   title: 'Average Delivery Time',
        //   details: [
        //     {
        //       text: 'Abeokuta',
        //       value: 'N 15'
        //     }
        //   ]
        // }
      ]
    }
  }, [dashboardData.data])

  const dispatch = useDispatch()

  const getQueryParams = filter => {
    const query = {}

    for (let key in filter) {
      if (key === 'start_month' || key === 'end_month') {
        if (filter[key]) {
          query[key] = filter[key].value
        }
      } else if (filter[key]) {
        query[key] = filter[key]
      }
    }

    return new URLSearchParams(query).toString()
  }

  useEffect(() => {
    dispatch(fetchBranches())
    dispatch(fetchDashboardOrders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const formatPayment = item => {
    switch (item) {
      case 'Transfer':
        return 'TRF'
      case 'Cash':
        return 'CASH'
      case 'POS':
        return 'POS'
      default:
        break
    }
  }

  const formatType = item => {
    switch (item) {
      case 'Local':
        return 'LC'
      case 'International':
        return `IN`
      default:
        return ''
    }
  }

  const formatTpl = item => {
    switch (item) {
      case 'FedEx':
        return 'FIE'
      case 'RedStar Express':
        return 'RSE'
      default:
        return item
    }
  }
  const removeEmptyValues = obj => {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key]
      }
    }
    return obj
  }

  useEffect(() => {
    if (dashboardData.filter) {
      const cardFilterParams = {
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date,
        branch: dashboardData.filter.branch_name,
        shipmentType: formatType(dashboardData.filter.shipmentType),
        payment_method: formatPayment(dashboardData.filter.payment),
        tpl_service: formatTpl(dashboardData?.filter.tpl)
      }
      const orderFilterParams = {
        branch: dashboardData.filter.branch_name,
        type: formatType(dashboardData.filter.shipmentType),
        tpl_service: formatTpl(dashboardData.filter.tpl),
        payment_method: formatPayment(dashboardData.filter.payment),
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date
      }
      dispatch(fetchDashboard(`?${getQueryParams(cardFilterParams)}`))
      dispatch(fetchDashboardOrders(removeEmptyValues(orderFilterParams)))
    } else {
      dispatch(fetchDashboard())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData.filter])

  const showFilter = () => {
    setIsFilterOpen(true)
  }

  useEffect(() => {
    if (dashboardData.data) {
      setLoading(false)
    }
  }, [dashboardData.data])

  const totals = useMemo(() => {
    if (dashboardData?.data) {
      return [
        {
          title: 'Total Revenue Generated',
          amount: dashboardData?.data?.revenue?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          })
        },
        {
          title: 'Total Shipments Created',
          amount: dashboardData?.data?.shipments?.total_shipments
        },
        {
          title: 'Total Number Of Customers',
          amount: dashboardData?.data?.total_customers
        },
        {
          title: 'Total Number Of Partners',
          amount: dashboardData?.data?.total_partners
        }
        // {
        //   title: 'Average Delivery Time',
        //   amount: '--'
        // }
      ]
    }
  }, [dashboardData.data])

  const shipmentsTypes = useMemo(() => {
    if (dashboardData?.data) {
      return {
        LC: dashboardData?.data?.shipments?.local_shipments,
        IN: dashboardData?.data?.shipments?.international_shipments
      }
    }
  }, [dashboardData.data])

  const orderStat = useMemo(() => {
    if (dashboardData.data) {
      return dashboardData?.data?.order_stat
    }
  }, [dashboardData.data])

  const shipmentsChartData = useMemo(() => {
    if (dashboardData.data) {
      return {
        total_shipments: dashboardData?.data?.shipments?.total_shipments,
        monthly_shipments: dashboardData?.data?.shipments?.monthly_shipments
      }
    }
  }, [dashboardData.data])

  const isLoading = useMemo(() => {
    return (
      loading ||
      !totals ||
      !shipmentsTypes ||
      !orderStat ||
      !shipmentsChartData ||
      !analysisData
    )
  }, [
    loading,
    orderStat,
    shipmentsChartData,
    shipmentsTypes,
    totals,
    analysisData
  ])

  const onApplyFilter = () => setLoading(true)

  const closeFilter = () => setIsFilterOpen(false)

  const onFilterDelete = () => {
    dispatch(fetchDashboardOrders())
    setLoading(true)
  }
  const loadOrders = useCallback(() => {
    dispatch(fetchDashboardOrders(queryParams))
  }, [queryParams])

  useEffect(() => {
    setSerializedData(null)
    loadOrders()
  }, [queryParams])

  const onPage = params => {
    setSerializedData(null)
    const orderFilterParams = () => {
      if (dashboardData.filter) {
        return {
          branch: dashboardData?.filter?.branch_name,
          type: formatType(dashboardData?.filter?.shipmentType),
          tpl_service: formatTpl(dashboardData?.filter.tpl),
          payment_method: formatPayment(dashboardData.filter.payment),
          start_date: dashboardData.filter.start_date,
          end_date:
            dashboardData.filter.date === 'today'
              ? dashboardData.filter.start_date
              : dashboardData.filter.end_date
        }
      }
    }

    setQueryParams(state => {
      return {
        page: state.page,
        page_size: state.page_size,
        ...params,
        ...(dashboardData.filter ? removeEmptyValues(orderFilterParams()) : {})
      }
    })
  }

  const totalVehices = useMemo(() => {
    return [
      {
        name: 'Active',
        value: 23,
        order_state: 2
      },
      {
        name: 'Available ',
        value: 23,
        order_state: 1
      },
      {
        name: 'Under repair',
        value: 24,
        order_state: 4
      }
    ]
  }, [])

  const totalDrivers = useMemo(() => {
    return [
      {
        name: 'Available',
        value: 23,
        order_state: 2
      },
      {
        name: 'In transit',
        value: 23,
        order_state: 1
      },
      {
        name: 'Unavailable ',
        value: 24,
        order_state: 4
      }
    ]
  }, [])

  return (
    <Page metaTitle={metaTitle} noPadding>
      <Page.Header title={'Dashboard'}>
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isFilterOpen && (
          <DashboardFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            onApplyFilter={onApplyFilter}
            isBranchSpecific={false}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        <DashboardFilter.Description onDelete={onFilterDelete} />
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-wrap'>
                {totals?.map((total, id) => (
                  <div
                    className='w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-2'
                    key={id}
                  >
                    <TotalCard title={total.title} total={total.amount} />
                  </div>
                ))}
              </div>

              <div className='flex flex-col gap-4'>
                <div className='flex flex-wrap -m-2'>
                  {CeoCardDetails?.map((cardDetails, ind) => (
                    <div
                      className='p-2 w-full sm:w-1/2 md:w-1/2 lg:w-1/4'
                      key={ind}
                    >
                      <CeoBookingCard
                        title={cardDetails.title}
                        amount={cardDetails.amount}
                        details={cardDetails.details}
                        isExpanded={expandedCardIndex === ind}
                        onToggle={() => handleToggle(ind)}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className='grid grid-cols-12 gap-4 mt-4'>
                <div className='col-span-12 lg:col-span-6'>
                  <ShipmentsBarChart data={shipmentsChartData} />
                </div>
                <div className='col-span-12 lg:col-span-6 grid grid-cols-2 gap-4 '>
                  <div className='col-span-2 md:col-span-1'>
                    <ShipmentTypePieChart data={shipmentsTypes} />
                  </div>
                  <div className='col-span-2 md:col-span-1'>
                    <OrderStatus data={orderStat} />
                  </div>
                </div>
              </div>

              {/* 
              Fleet Report
              <div className='mb-6 mt-4'>
                <h1 className='text-2xl leading-9 font-semibold pb-4'>
                  Fleet Report
                </h1>
                <div className='grid grid-cols-12 gap-4'>
                  <div className='col-span-12 lg:col-span-8'>
                    <ShipmentsBarChart data={shipmentsChartData} />
                  </div>
                  <div className='col-span-12 lg:col-span-4 gap-4'>
                    <div>
                      <FleetCard
                        title='Total no of vehicle: 8'
                        statuses={totalVehices}
                      />
                    </div>
                    <div className='mt-4'>
                      <FleetCard
                        title='Total no of Drivers: 8'
                        statuses={totalDrivers}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <div>
                <h1 className='text-2xl leading-9 font-semibold'>
                  Financial Analysis
                </h1>
                <FinancialAnalysis
                  analysisData={analysisData}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                />
              </div>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  )
}
