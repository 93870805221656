import React, { useState, useEffect, useContext } from 'react'
import { AppForm, AppFormField } from '../components'
import * as yup from 'yup'
import authApi from '../api/auth'
import useToast from '../hooks/useToast'
import { CircularProgress } from '@mui/material'
import Page from '../containers/Page'
import { Formik } from 'formik'
import { parseError } from '../utils'
import { AuthLayoutContext } from '../containers/AuthLayout'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../slices/authSlice'

const validationSchema = yup.object().shape({
  old_password: yup.string().required('Please enter your previous password'),
  new_password: yup.string().required('Please enter your new password'),
  confirm_password: yup
    .string()
    .required('Type new password again')
    .test('passwords-match', "Passwords don't match", function (value) {
      return this.parent.new_password === value
    })
})

export default function AccountSettings ({ metaTitle }) {
  const userData = useSelector(state => state.auth.user)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [initialValues, setInitialValues] = useState({
    full_name: '',
    user_id: '',
    phone_number: '',
    role: '',
    email: '',
    password: '',
    branch_name: '',
    branch_address: '',
    timezone: ''
  })

  const { userRole } = useContext(AuthLayoutContext)

  const toast = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    setInitialValues({
      full_name: `${userData?.employee?.first_name} ${userData?.employee?.last_name}`,
      user_id: userData?.user?.user_id,
      phone_number: userData?.branch?.phone_number
        ? userData?.branch?.phone_number
        : '--',
      role: userRole.name || '--',
      email: userData?.user?.email,
      password: '',
      branch_name: userData?.branch?.name,
      branch_address: userData?.branch?.address,
      timezone: userData?.branch?.user ? userData?.branch?.user : '--'
    })
  }, [])

  const handleOnSubmit = async values => {
    if (!loading) {
      setError(null)
      setLoading(true)
      const response = await authApi.changePassword({
        old_password: values.old_password,
        new_password: values.new_password
      })
      setLoading(false)
      if (!response.ok) {
        setLoading(false)
        setError(null)
        const apiError = parseError(response)
        if (apiError) {
          const error = Object.values(apiError.data)[0][0]
          setError(error)
        }
        return
      }
      setLoading(null)
      setError(null)
      window.alert(
        'Password changed successfully. You will now be required to log in with your new password.'
      )
      dispatch(authActions.logout())
    }
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Account'}></Page.Header>
      <Page.Body>
        {error && (
          <p className='text-red-500 text-center mt-4'>Error: {error}</p>
        )}
        <div className='bg-white px-10 mt-6 py-[45px] w-full max-w-full mx-auto rounded-lg'>
          <h2 className='font-semibold text-2xl text-start mb-9'>
            Account Details
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <div>
              <AppForm padding='0'>
                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap max-h-[70px]'>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>Full Name</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userData?.employee?.first_name}{' '}
                      {userData?.employee?.last_name}
                    </p>
                  </div>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>User ID</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userData?.user?.user_id}
                    </p>
                  </div>
                </div>
                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap'>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>Phone Number</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userData?.branch?.phone_number
                        ? userData?.branch?.phone_number
                        : '--'}
                    </p>
                  </div>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>Role</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userRole.name || '--'}
                    </p>
                  </div>
                </div>

                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap w-1/2'>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>Email</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userData?.user?.email || '--'}
                    </p>
                  </div>
                </div>
              </AppForm>
            </div>
          </Formik>
        </div>
        <div className='bg-white px-10 mt-6 py-[45px] w-full max-w-full mx-auto rounded-lg'>
          <h2 className='font-semibold text-2xl text-start mb-9'>
            Branch details
          </h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <AppForm padding='0'>
              <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap'>
                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap w-1/2'>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>Branch Name</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userData?.branch?.name || '--'}
                    </p>
                  </div>
                </div>
                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap w-1/2'>
                  <div className=' w-full'>
                    <p className='mb-2 text-sm'>Branch Address</p>
                    <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                      {userData?.branch?.address || '--'}
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap '>
                <div className=' w-full'>
                  <p className='mb-2 text-sm'>Time Zone</p>
                  <p className='border rounded-md px-[18px] border-[#CBCBCB80] text-base font-normal py-3 bg-[#F5F5F54D]'>
                    {userData?.branch?.zone || '--'}
                  </p>
                </div>
              </div>
            </AppForm>
          </Formik>
        </div>

        <div className='bg-white px-10 mt-6 py-[45px] w-full max-w-full mx-auto rounded-lg'>
          <h2 className='font-semibold text-2xl text-start mb-9'>
            Change Password
          </h2>
          <Formik
            initialValues={{
              old_password: '',
              new_password: '',
              confirm_password: ''
            }}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ handleSubmit }) => (
              <AppForm padding='0' onSubmit={handleSubmit}>
                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap'>
                  <AppFormField
                    name='old_password'
                    title='Previous Password'
                    type='password'
                    toggleVisibility
                  />
                  <AppFormField
                    name='new_password'
                    title='New Password'
                    type='password'
                    toggleVisibility
                  />
                </div>
                <div className='flex items-center gap-4 sm:flex-nowrap flex-wrap'>
                  <AppFormField
                    name='confirm_password'
                    title='Confirm New Password'
                    type='password'
                    toggleVisibility
                  />
                </div>

                <button type='submit' className='btn btn-primary'>
                  {loading ? (
                    <span className='ml-2'>
                      <CircularProgress size={28} color='inherit' />
                    </span>
                  ) : (
                    'Submit'
                  )}
                </button>
              </AppForm>
            )}
          </Formik>
        </div>
      </Page.Body>
    </Page>
  )
}
