import useDocumentTitle from '../hooks/useDocumentTitle'
import { useContext, useEffect, useRef } from 'react'
import TopNav from '../components/globals/TopNav'
import { BackButton } from '../components'
import { headerShadowOnScroll } from '../utils/animate'
import { AuthLayoutContext } from './AuthLayout'

export default function Page ({ metaTitle, children }) {
  useDocumentTitle(`${metaTitle} | AAJ Transport Pro`)

  const { layoutContainer } = useContext(AuthLayoutContext)

  useEffect(() => {
    if (layoutContainer) {
      layoutContainer.scrollTo(0, 0)
    }
  }, [layoutContainer])

  return (
    <div aria-describedby='page-container'>
      {children}
      <Page.Footer />
    </div>
  )
}

Page.Header = function PageHeader ({ title, backRoute, onBack, children }) {
  const headerRef = useRef()

  useEffect(() => {
    if (headerRef.current) {
      headerShadowOnScroll(headerRef.current)
    }
  }, [])

  return (
    <div
      aria-describedby='page header'
      className='sticky-header bg-g-200 z-20'
      ref={headerRef}
    >
      <TopNav />
      <div className='flex flex-col md:flex-row gap-2 items-start md:items-center flex-wrap px-4 lg:px-6 py-2'>
        <div className='flex items-center gap-2 w-full md:w-fit'>
          {backRoute && <BackButton to={backRoute} onBack={onBack} />}
          <h1 className='font-bold text-2xl'>{title}</h1>
        </div>
        <div className='flex justify-end items-start gap-2 md:gap-3 ml-auto flex-wrap'>
          {children}
        </div>
      </div>
    </div>
  )
}

Page.Body = function PageBody ({ children }) {
  return (
    <div
      aria-describedby='page body'
      className='px-4 lg:px-6 pb-20 min-h-[80vh]'
    >
      {children}
    </div>
  )
}

Page.Footer = function PageFooter () {
  const year = new Date().getFullYear()
  return (
    <div
      aria-describedby='page footer'
      className='px-4 lg:px-6 pb-4 text-sm text-center text-g-600 mt-auto mx-auto'
    >
      &copy; AAJ Transport Pro {year}
    </div>
  )
}
