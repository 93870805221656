import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import useToast from "../../hooks/useToast";
import { CircularProgress } from "@mui/material";
import { ReactComponent as Times } from "../../assets/times.svg";
import { ReactComponent as User } from "../../assets/assign-user.svg";
import { ReactComponent as Bin } from "../../assets/bin.svg";
import { ReactComponent as Exception } from "../../assets/exception.svg";
import { ReactComponent as Truck } from "../../assets/truck.svg";
import { IoIosSearch } from "react-icons/io";
import manifestApi from "../../api/manifest";

const ShipmentRaiseException = ({
  sn,
  id,
  setOpenModal,
  tracking_id,
  clickThreeDots,
  removeShipment,
  loading,
  isManifest,
}) => {
  const dispatch = useDispatch();
  const ridersData = useSelector((state) => state?.riders?.riders);
  const toast = useToast();
  const [toggleMore, setToggleMore] = useState(0);
  const [assignee, setAssignee] = useState(false);
  const [riderId, setRiderId] = useState("");
  const [riderName, setRiderName] = useState("");
  const [selected, setSelected] = useState(false);
  const [checkSelected, setCheckSelected] = useState("");
  const [riderVal, setRiderVal] = useState([]);
  const [loading2, setLoading] = useState(false);
  const [riderSearchedVal, setRiderSearchedVal] = useState("");

  const handleSelected = (value) => {
    setCheckSelected(value);
  };

  const handleAssign = async () => {
    setLoading(true);
    const result = await manifestApi.assignManifest(tracking_id, {
      manifest_type: "CM",
      rider: { id: riderId, name: riderName },
    });

    if (result.ok) {
      toast("Rider assigned", "success");
      setLoading(false);
    } else {
      toast(result?.problem, "error");
      setLoading(false);
    }
    setToggleMore(id);
    setAssignee(false);
    setSelected(false);
  };

  useEffect(() => {
    if ((ridersData?.length !== 0) & (riderSearchedVal === "")) {
      setRiderVal(ridersData);
    }
  }, [ridersData, riderSearchedVal]);

  const findRider = (value) => {
    if (value?.length !== 0) {
      const searchData = ridersData?.filter((item) =>
        item?.name?.includes(value)
      );
      setRiderVal(searchData);
    } else {
      setRiderVal(ridersData);
    }
  };

  return (
    <Popover className="relative">
      <PopoverButton>
        <BsThreeDotsVertical
          className="cursor-pointer -z-10"
          onClick={() => {
            setToggleMore(id + 1);
            clickThreeDots();
          }}
        />
      </PopoverButton>
      {toggleMore === sn && (
        <PopoverPanel className="absolute -left-40 mt-2 -translate-x-1/2 transform">
          <div className="overflow-hidden shadow-lg w-[300px] bg-white py-4">
            <span
              className="flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7]"
              onClick={() => setOpenModal(true)}
            >
              <Exception />
              <p>Raise exception</p>
            </span>
          </div>
        </PopoverPanel>
      )}
    </Popover>
  );
};

export default ShipmentRaiseException;
