import React, { useState } from 'react'
import { AppForm, AppFormField } from '../components'
import * as yup from 'yup'
import Loader from '../components/loader/Loader'
import authApi from '../api/auth'
import logo from '../assets/logo-text.png'
import useToast from '../hooks/useToast'
import { parseError, setAuthCredsToCookies } from '../utils'
import { Formik } from 'formik'
import useDocumentTitle from '../hooks/useDocumentTitle'
import cookies from '../utils/cookies'
import { useDispatch } from 'react-redux'
import { authActions } from '../slices/authSlice'
import packageJson from '../package.alias.json'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please provide a valid email address')
    .required('Email address is required'),
  password: yup.string().required('Please provide a valid password')
})

const Login = ({ metaTitle }) => {
  useDocumentTitle(`${metaTitle} | AAJ Transport Pro`)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const toast = useToast()
  const dispatch = useDispatch()

  const handleError = error => {
    setLoading(false)
    setError(true)

    const apiError = parseError(error)

    if (apiError) {
      if (apiError.status >= 500) {
        toast(apiError.data.message, 'error')
      } else {
        toast('Invalid credentials, or user is inactive.', 'error')
      }
    }
  }

  const handleOnSubmit = async ({ email, password }) => {
    setLoading(true)
    setError(false)

    const loginResponse = await authApi.login({ email, password })

    if (!loginResponse.ok) {
      handleError(loginResponse)
      return
    }

    const { token, refresh_token, expire_at } = loginResponse.data.payload

    setAuthCredsToCookies({
      token,
      refresh_token,
      expire_at
    })

    const tokenResponse = await authApi.createAccessToken()

    if (!tokenResponse.ok) {
      handleError(tokenResponse)
      return
    }
    cookies.set('accessToken', tokenResponse.data.access_token)

    dispatch(authActions.login())
    dispatch(
      authActions.setUser({
        user: loginResponse.data.payload.user,
        ...loginResponse.data.payload.account_info
      })
    )

    setLoading(false)
  }

  return (
    <div className='w-full bg-white'>
      <div className='mx-auto w-full max-w-xl min-h-screen flex flex-col justify-center items-center'>
        {!loading ? (
          <>
            <div className='mb-14'>
              <img src={logo} alt='logo' className='w-28 h-28 object-contain' />
            </div>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit }) => (
                <AppForm onSubmit={handleSubmit} padding='8'>
                  <h1 className='font-bold text-3xl text-center'>
                    Log in to your account
                  </h1>
                  {error && (
                    <p className='text-red-500'>
                      Something went wrong, please try again or verify the
                      details provided
                    </p>
                  )}
                  <AppFormField
                    name='email'
                    title='Email Address'
                    type='email'
                  />
                  <AppFormField
                    name='password'
                    title='Password'
                    type='password'
                    toggleVisibility
                  />
                  <button
                    className='btn btn-primary btn-lg text-white'
                    type='submit'
                  >
                    Login
                  </button>
                </AppForm>
              )}
            </Formik>
          </>
        ) : (
          <Loader />
        )}

        <div className='mt-auto pb-4'>
          <span className='text-xs whitespace-nowrap text-dark-primary/60 font-medium tracking-wide select-none'>
            v{packageJson.version}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Login
