import { ReactComponent as Times } from '../../../assets/times.svg'
import SelectDropdown from '../selectDropdown'
import { ReactComponent as Caret } from '../../../assets/dropdown-caret.svg'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export default function Filter ({ onCancel, children }) {
  return (
    <div>
      <div className='flex items-center w-full justify-between'>
        <h3 className='text-lg text-dark-primary font-medium pl-4'>Filter</h3>

        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer'
          onClick={onCancel}
        >
          <Times />
        </div>
      </div>
      <div className='flex flex-col p-4 gap-6 text-sm text-dark-primary'>
        {children}
      </div>
    </div>
  )
}

Filter.SelectDropdown = function FilterSelectDropdown ({
  title,
  value,
  placeholder,
  options,
  onChange
}) {
  const [show, setShow] = useState(false)
  const toggleDropdown = () => setShow(state => !state)

  const onSelect = val => {
    onChange(val)
    toggleDropdown()
  }

  return (
    <div className='flex flex-col gap-4'>
      <div>{title}</div>
      <div
        className='w-full flex items-center relative'
        onClick={toggleDropdown}
      >
        <div className='flex items-center justify-between gap-2 border-[0.5px] border-gray-200 w-full p-2 rounded-md text-[#333333b3] cursor-pointer'>
          <span>
            {value
              ? options.find(opt => opt.value === value)?.name
              : placeholder}
          </span>
          <Caret />
        </div>
        <SelectDropdown
          show={show}
          options={options}
          selected={value}
          onSelect={onSelect}
        />
      </div>
    </div>
  )
}

Filter.Radio = function FilterRadio ({ title, name, value, options, onChange }) {
  return (
    <div className='flex flex-col gap-4'>
      <div>{title}</div>
      <div className='flex flex-col gap-3'>
        {options.map(({ name: _name, value: _value }, id) => (
          <label
            htmlFor={_name}
            className='flex items-center gap-4 cursor-pointer'
            key={id}
          >
            <input
              type='radio'
              name={name}
              className='radio radio-primary rounded-md'
              value={_value}
              checked={value === `${_value}`}
              onChange={onChange}
              id={_name}
            />
            <span className='text-[#333333b3]'>{_name}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

Filter.CheckBox = function FilterCheckBox ({ title, name, checked, onChange }) {
  return (
    <div className='flex flex-col gap-4'>
      <label htmlFor={name} className='flex items-center gap-4 cursor-pointer'>
        <input
          type='checkbox'
          checked={checked}
          name={name}
          className='checkbox checkbox-primary rounded-md'
          onChange={onChange}
        />
        <span className='text-[#333333b3]'>{title}</span>
      </label>
    </div>
  )
}

Filter.Submit = function FilterSubmit ({
  disabled,
  onSubmit,
  text = 'Apply Filters'
}) {
  return (
    <div className='mt-10 ml-auto'>
      <button
        className={twMerge(
          'btn btn-rounded min-w-24',
          disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
        )}
        disabled={disabled}
        onClick={onSubmit}
      >
        {text}
      </button>
    </div>
  )
}
