import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import salesforceApi from "../api/salesforce"
import { parseError } from '../utils'

const initialState = {
    salesforce: null,
    singleSalesforce:null,
    commision:null
  }



export const fetchSalesforce = createAsyncThunk('salesforce/fetch', async () => {
    const response = await salesforceApi.getSalesforce()
  
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }
  
    return response.data
  })

  export const fetchSingleSalesforce = createAsyncThunk('singleSalesforce/fetch', async (data) => {
    const response = await salesforceApi.getSingleSalesforce(data)
  
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }
  
    return response.data
  })

  export const fetchSalesForceCommision = createAsyncThunk('salesforceCommission/fetch', async (data,params) => {
    const response = await salesforceApi.getCommision(data,params)
  
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }
  
    return response.data
  })



  const salesforceSlice = createSlice({
    name: 'salesforce',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(fetchSalesforce.fulfilled, (state, action) => {
        state.salesforce = action?.payload
      }).addCase(fetchSingleSalesforce.fulfilled, (state, action) => {
        state.singleSalesforce = action?.payload
      }).addCase(fetchSalesForceCommision.fulfilled,(state,action)=>{
        state.commision = action?.payload
      })
    },
   
        
      
  })
  
  export default salesforceSlice.reducer