import React, { useEffect, useMemo, useState, useContext } from 'react'
import ordersApi from '../../api/orders'
import Loader from '../../components/loader/Loader'
import useToast from '../../hooks/useToast'
import { Navigate, useSearchParams, useLocation } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import { fetchHubs } from '../../slices/hubsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { parseError } from '../../utils'
import ToggleSwitch from '../../components/globals/ToggleSwitch'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'

const ShipmentSection = ({ metaTitle }) => {
  const { order, updateActiveStep, updateOrder, resolvePathname } =
    useContext(CreateOrderContext)

  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('ord')
  const quoteId = searchParams.get('qt')

  const initFormData = () => {
    const config = {
      description: order.payload.description || '',
      type: order.payload.type,
      package_insurance: order.payload.package_insurance || ''
    }

    if (order.payload.type === 'IN') {
      config.delivery_type = order.payload.delivery_type || ''
    }
    if (order.payload.type === 'LC') {
      config.delivery_mode = order.payload.delivery_mode || ''
      config.category = order.payload.category
      // eslint-disable-next-line default-case
      switch (config.category) {
        case 1:
          config.package_insurance = 'EI'
          break
        case 2:
          config.package_insurance = 'NE'
          break
        case 3:
          config.package_insurance = 'HI'
          break
      }
      // config.get_acknowledgement_copy = true
    }
    return config
  }

  const [formData, setFormData] = useState(initFormData())
  const [loading, setLoading] = useState(false)

  const navigateWithParams = useNavigateWithParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const hubsStore = useSelector(state => state.hubs)

  useEffect(() => {
    if (order.payload.type === 'LC') {
      dispatch(fetchHubs())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateOrder(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  useEffect(() => {
    if (formData.delivery_mode === 'PICKUP') {
      setFormData(state => ({ ...state, pickup_hub: '' }))
    }
  }, [formData.delivery_mode])

  const backRoute = useMemo(() => {
    if (order.payload.packages) {
      switch (order.payload.packages.type) {
        case 'document':
          return `${ROUTES.ORDERS.CREATE_ORDER.DOCUMENT.path}${location.search}`
        case 'fish/snail':
          return `${ROUTES.ORDERS.CREATE_ORDER.FISH_SNAIL.path}${location.search}`
        default:
          return `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path}${location.search}`
      }
    }
  }, [location.search, order.payload.packages])

  useEffect(() => {
    updateActiveStep({
      stepId: 5,
      metaTitle,
      back: backRoute
    })
  }, [backRoute, metaTitle, updateActiveStep])

  const hubs = useMemo(() => {
    let result = []
    if (hubsStore.hubs) {
      result = [...hubsStore.hubs]
      return result.sort((a, b) => {
        if (a.location === b.location) {
          return a.name.localeCompare(b.name)
        }
        return a.location.localeCompare(b.location)
      })
    }
    return result
  }, [hubsStore.hubs])

  const toast = useToast()

  const handleFieldChange = ({ target }) => {
    setFormData(state => ({ ...state, [target.name]: target.value }))
  }

  const toggleAcknowledgementCopy = value => {
    setFormData(state => ({ ...state, get_acknowledgement_copy: value }))
  }

  const handleOnSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    const payload = {
      sender: order.payload.sender,
      receiver: order.payload.receiver,
      partner: order.payload.partner,
      type: order.payload.type,
      package_insurance: order.payload.package_insurance,
      packages: order.payload.packages,
      tpl_service: order.payload.tpl_service,
      description: order.payload.description,
      served_by: order.payload.served_by,
      branch_name: order.payload.branch_name,
      draft: false,
      ...(order.payload.type === 'LC' && {
        category: order.payload.category,
        delivery_mode: order.payload.delivery_mode,
        get_acknowledgement_copy: order.payload.get_acknowledgement_copy,
        ...(order.payload.delivery_mode === 'PICKUP' && {
          pickup_hub: hubs.find(
            hub => hub.id === Number(order.payload.pickup_hub)
          )
        })
      }),
      ...(order.payload.type !== 'LC' && {
        delivery_type: order.payload.delivery_type
      }),
      ...(order.payload.package_insurance === 'PM' && {
        insured_value: order.payload.insured_value
      })
    }

    const response =
      orderId && quoteId
        ? await ordersApi.createOrderFromQuote(orderId, quoteId, payload)
        : order.meta.order_id
        ? await ordersApi.updateOrder(order.meta.order_id, payload)
        : await ordersApi.createOrder(payload)

    if (!response.ok) {
      setLoading(false)

      const apiError = parseError(response)
      if (apiError) {
        let message
        if (apiError.status >= 500) {
          if (
            apiError.data.error?.includes(
              'Order matching query does not exist.'
            )
          ) {
            message =
              'Cannot create a new order from this quote. An order already exists.'
          } else {
            message = apiError.data.message
          }
        } else if (apiError.status === 403) {
          message = apiError.data.errors[0].detail
        } else if (apiError.status === 400) {
          if (Array.isArray(apiError.data.detail)) {
            message = apiError.data.detail[0].message
          } else {
            message = Object.values(apiError.data.detail)[0][0]?.message
          }
        } else {
          message =
            Object.values(apiError.data.detail)[0][0]?.message ||
            apiError.data.error
        }

        toast(
          message ||
            'Unable to complete order. Check entry details and try again.',
          'error'
        )
      }

      return
    }

    window.sessionStorage.setItem(
      'order',
      JSON.stringify(response.data.payload)
    )
    navigateWithParams({
      pathname: resolvePathname(ROUTES.ORDERS.CREATE_ORDER.ORDER_SUMMARY.path)
    })
  }

  if (!order.payload.type) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
      />
    )
  }

  if (!order.payload.sender) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=sender`
        )}
      />
    )
  }

  if (!order.payload.receiver) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}?ctm=receiver`
        )}
      />
    )
  }

  if (order.payload.type === 'LC' && !order.payload.category) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}?ctm=sender&ord=${orderId}&qt=${quoteId}`
            : `${ROUTES.ORDERS.CREATE_ORDER.PACKAGE_TYPE.path}`
        )}
      />
    )
  }

  if (!order.payload.packages) {
    return (
      <Navigate
        replace={true}
        to={resolvePathname(
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.CUSTOMER_INFO.path}${location.search}&ctm=sender`
            : `${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`
        )}
      />
    )
  }

  return (
    <div>
      <h2 className='py-4 md:pt-0 font-bold text-2xl text-center'>
        Shipment Information
      </h2>
      <div className='flex flex-col items-center pb-8'>
        {loading ? (
          <Loader />
        ) : (
          <div className='w-full max-w-3xl'>
            <form
              onSubmit={handleOnSubmit}
              className='flex flex-col w-full bg-white py-8 px-4 rounded-lg justify-center space-y-6 border border-neutral'
              id='shipment-form'
            >
              <div className='form-control w-full'>
                <label className='label'>
                  <span className='label-text'>General Description</span>
                </label>
                <input
                  onChange={handleFieldChange}
                  value={formData.description}
                  name='description'
                  className='input'
                  placeholder='Description'
                  type='text'
                  required
                />
              </div>
              <div className='form-control w-full'>
                <label className='label'>
                  <span className='label-text'>Shipment Type</span>
                </label>
                <select
                  name='type'
                  className='select'
                  value={formData.type}
                  required
                  aria-readonly='true'
                  disabled
                >
                  <option value=''>Select</option>
                  <option value='LC'>Local</option>
                  <option value='IN'>International</option>
                </select>
              </div>
              {formData.type === 'IN' ? (
                <div className='form-control w-full'>
                  <label className='label'>
                    <span className='label-text'>Delivery Type</span>
                  </label>
                  <select
                    onChange={handleFieldChange}
                    value={formData.delivery_type}
                    name='delivery_type'
                    className='select'
                    required
                  >
                    <option value=''>Select</option>
                    <option value='DROP_OFF'>Drop Off</option>
                    <option value='SIGNATURE_REQUIRED'>Signature</option>
                  </select>
                </div>
              ) : (
                <div className='form-control w-full'>
                  <label className='label'>
                    <span className='label-text'>Delivery Mode</span>
                  </label>
                  <select
                    onChange={handleFieldChange}
                    value={formData.delivery_mode}
                    name='delivery_mode'
                    className='select'
                  >
                    <option value=''>Select</option>
                    <option value='DOOR_STEP'>DOORSTEP</option>
                    <option value='PICKUP'>PICKUP</option>
                  </select>
                </div>
              )}

              {formData.delivery_mode === 'PICKUP' && (
                <div className='form-control w-full'>
                  <label className='label'>
                    <span className='label-text'>Pickup Hub</span>
                  </label>
                  <select
                    onChange={handleFieldChange}
                    value={formData.pickup_hub}
                    name='pickup_hub'
                    className='select'
                    required
                  >
                    <option value=''>Select</option>
                    {hubs.map(hub => (
                      <option value={hub.id}>{`${
                        hub.location.charAt(0).toUpperCase() +
                        hub.location.slice(1)
                      } - ${
                        hub.name.charAt(0).toUpperCase() + hub.name.slice(1)
                      }`}</option>
                    ))}
                  </select>
                </div>
              )}

              <div className='form-control w-full'>
                <label className='label'>
                  <span className='label-text'>Insurance</span>
                </label>
                <select
                  onChange={handleFieldChange}
                  value={formData.package_insurance}
                  name='package_insurance'
                  className='select'
                  required
                  disabled={formData.type === 'LC'}
                >
                  <option value=''>Select</option>
                  {formData.type === 'LC' ? (
                    <>
                      <option value='EI'>Electronics Insurance</option>
                      <option value='NE'>Non Electronics Insurance</option>
                      <option value='HI' disabled>
                        Haulage Insurance
                      </option>
                    </>
                  ) : (
                    <>
                      <option value='FR'>Free</option>
                      {/* <option value='SD'>Standard</option> */}
                      <option value='PM'>Premium</option>
                    </>
                  )}
                </select>
              </div>
              {formData.package_insurance === 'PM' && (
                <div className='form-control w-full'>
                  <label className='label'>
                    <span className='label-text'>Insured Value</span>
                  </label>
                  <input
                    onChange={handleFieldChange}
                    value={formData.insured_value}
                    name='insured_value'
                    className='input'
                    placeholder='Insured Value'
                    type='number'
                    required
                  />
                  <p className='text-primary self-end mt-1'>
                    {(formData.insured_value * 1 || 0).toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN'
                    })}
                  </p>
                </div>
              )}
              {/* {formData.type === 'LC' && (
                <div className='form-control w-full'>
                  <div className='flex items-center gap-4'>
                    <label className='label'>
                      <span className='label-text'>Acknowledgment Copy</span>
                    </label>

                    <ToggleSwitch
                      enabled={formData.get_acknowledgement_copy}
                      onChange={toggleAcknowledgementCopy}
                    />
                  </div>
                </div>
              )} */}
              <button
                className='btn btn-primary text-white'
                type='submit'
                form='shipment-form'
              >
                Continue
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export default ShipmentSection
